<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks">
      <template #actions v-if="$store.state.system.superAdmin">
        <lms-page-header-action-btn icon="mdi-plus" @click="create"
          >create</lms-page-header-action-btn
        >
      </template>
    </f-page-header>

    <lms-default-loader v-if="isLoading" />

    <f-training-list v-if="!isLoading" />
  </div>
</template>

<script>
import FTrainingList from '../../../components/views/settings/data/TrainingTypeList.vue';

export default {
  components: {
    FTrainingList,
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
      ];
    },
    title() {
      return this.$t('labels.trainingType');
    },
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$store.dispatch('setting/listTrainingTypes');
    },
    create() {
      this.$router.push({
        name: 'settings-training-type-create',
      });
    },
  },
};
</script>
